.color-selector {
	display: inline-block;
	border-radius: 4px;
	position: relative;
	padding: 12px 14px 12px;
	background: white;
	cursor: pointer;
	border: 1px solid #c4c4c4;
	transition: border-color 0.3s;
	min-width: 146px;
	color: #000000de;
}

.color-selector:hover {
	border-color: #000;
}

.color-selector .circle {
	display: inline-block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 1px solid #eee;
	margin-right: 10px;
	cursor: pointer;
}

.color-selector .hidden {
	position: absolute;
	left: 0;
	opacity: 0;
}

.color-selector span {
	display: inline-block;
	vertical-align: middle;
}
