/*Navigation layouts*/
#titleToolbarGrid {
	margin: auto;
	padding-top: 25px;
}

#menuTabs {
	background: #f86900;
	height: 30px;
	margin-bottom: 0;
	border: none;
}

#resizePanel > div:nth-child(3) {
	box-shadow: -4px 0 12px 0 rgb(0 0 0 / 20%);
}

fieldset > legend {
	width: unset;
}

.react-grid-layout {
	position: relative;
	transition: height 200ms ease;
}
.react-grid-item {
	transition: all 200ms ease;
	transition-property: left, top;
}
.react-grid-item.cssTransforms {
	transition-property: transform;
}
.react-grid-item.resizing {
	z-index: 1;
	will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
	transition: none;
	z-index: 3;
	will-change: transform;
}

.react-grid-item.react-grid-placeholder {
	background: red;
	opacity: 0.2;
	transition-duration: 100ms;
	z-index: 2;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.react-grid-item > .react-resizable-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	bottom: 0;
	right: 0;
	background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
	background-position: bottom right;
	padding: 0 3px 3px 0;
	background-repeat: no-repeat;
	background-origin: content-box;
	box-sizing: border-box;
	cursor: se-resize;
}

/* MENU DROPDOWN */
.nav-item {
	position: relative;
	white-space: nowrap;
}

.nav-item:hover .menu-dropdown-list {
	display: block;
}

.menu-dropdown-list {
	padding-left: 0;
	display: none;
	list-style: none;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 101;
	min-width: 120px;
}

.login-form {
	background-color: white;
	padding: 20px 80px;
}

.login-form .logo {
	display: flex;
	justify-content: center;
	padding: 20px 0;
}

.login-form .logo img {
	width: 300px;
	height: 60px;
}

.login-form .form-element {
	margin-bottom: 5px;
	display: flex;
	border-radius: 5px;
	border: 1px solid #ddd;
	overflow: hidden;
}

.login-form .form-element label {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 160px;
	background-color: #eaeaea;
	color: #888;
	padding: 8px 0;
	margin-bottom: 0;
}

.login-form .form-element input {
	padding: 8px 10px;
	flex: 1;
	outline: none;
	border: none;
}

.login-form .connect-btn {
	margin-top: 15px;
	box-shadow:
		0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12),
		0 3px 1px -2px rgba(0, 0, 0, 0.2);
	font-size: 18px;
	line-height: 24px;
	padding: 5px 10px;
	background-color: white;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	column-gap: 6px;
}

.login-form .cancel-btn {
	margin-top: 15px;
	box-shadow:
		0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12),
		0 3px 1px -2px rgba(0, 0, 0, 0.2);
	font-size: 18px;
	line-height: 24px;
	padding: 5px 10px;
	background-color: white;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	column-gap: 6px;
	margin-left: auto;
	margin-right: 0;
}

/* Custom MUI */
.MuiDataGrid-rowCount {
	text-transform: lowercase !important;
	display: block !important;
}

.MuiDataGrid-rowCount::first-letter {
	text-transform: uppercase !important;
}
